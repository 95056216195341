/* eslint-disable react-hooks/exhaustive-deps */
import "./css/normalize.css"; //CSS reset
import "./css/site.css";

import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { PAGE_ROUTES } from "../utils/stateTypes";
import { saveEntryPoint, loadEntryPoint } from "../utils/entryStorage";
import Page404 from "./home/404";
import ScrollToTop from "./general/ScrollToTop";
import TopNav from "./home/TopNav";
import Footer from "./home/Footer";
import Home from "./home/Home";
import HomeCorporate from "./home/HomeCorporate";
import HomeBDiamond from "./home/HomeBDiamond";
import HomeLoveIsLove from "./home/HomeLoveIsLove";
import HomeBannerAds from "./home/HomeBannerAds";
import About from "./about/About";
import Venues from "./venues/Venues";
import GreatHall from "./venues/GreatHall";
import Shepstone from "./venues/Shepstone";
import Florals from "./florals/Florals";
import Gallery from "./gallery/Gallery";
import Contact from "./contact/Contact";
import BlogHome from "./blog/BlogHome";
import BlogPost from "./blog/BlogPost";
import Shop from "./shop/Shop";
import ThankYou from "./shop/ThankYou";
import MarketVendor from "./market/MarketVendor";

const App = () => {
  let location = useLocation();

  React.useEffect(() => {
    //First render, save & load entry point
    saveEntryPoint(location.pathname);
    loadEntryPoint();
  }, []);

  return (
    <main>
      <ScrollToTop />
      <TopNav />
      <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          classNames={"fade"}
          timeout={300}
        >
          <Switch location={location}>
            <Route
              exact
              path={PAGE_ROUTES.HOME}
              component={Home}
            />
            <Route
              exact
              path={PAGE_ROUTES.HOMECORPORATE}
              component={HomeCorporate}
            />
            <Route
              exact
              path={PAGE_ROUTES.HOMEBDIAMOND}
              component={HomeBDiamond}
            />
            <Route
              exact
              path={PAGE_ROUTES.HOMELOVEISLOVE}
              component={HomeLoveIsLove}
            />
            <Route
              exact
              path={PAGE_ROUTES.HOMEBANNERADS}
              component={HomeBannerAds}
            />
            <Route
              exact
              path={PAGE_ROUTES.ABOUT}
              component={About}
            />
            <Route
              exact
              path={PAGE_ROUTES.VENUES}
              component={Venues}
            />
            <Route
              exact
              path={PAGE_ROUTES.VENUES_HALL}
              component={GreatHall}
            />
            <Route
              exact
              path={PAGE_ROUTES.VENUES_SHEP}
              component={Shepstone}
            />
            <Route
              exact
              path={PAGE_ROUTES.FLORALS}
              component={Florals}
            />
            <Route
              exact
              path={PAGE_ROUTES.GALLERY}
              component={Gallery}
            />
            <Route
              exact
              path={PAGE_ROUTES.CONTACT}
              component={Contact}
            />
            <Route
              exact
              path={PAGE_ROUTES.BLOG}
              component={BlogHome}
            />
            <Route
              exact
              path={PAGE_ROUTES.BLOG_POST}
              component={BlogPost}
            />
            <Route
              exact
              path={PAGE_ROUTES.SHOP}
              component={Shop}
            />
            <Route
              exact
              path={PAGE_ROUTES.THANK_YOU}
              component={ThankYou}
            />
            <Route
              exact
              path={PAGE_ROUTES.THANK_YOU}
              component={ThankYou}
            />
            <Route
              exact
              path={PAGE_ROUTES.MARKET_VENDOR}
              component={MarketVendor}
            />
            <Route
              component={Page404}
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </main>
  );
};

export default App;