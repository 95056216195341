import React from "react";
import { Helmet } from "react-helmet";
import { Button } from "@material-ui/core";
import { WhatsApp } from "@material-ui/icons";
import { Link } from "react-router-dom";

import Header from "../sections/Header";
import LineSeparator from "../sections/LineSeparator";
import GreenBlockSection from "../sections/GreenBlockSection";

const styles = {
  contactButton: {
    width: "100%",
    maxWidth: "300px",
    height: "50px",
    marginBottom: "20px",
    textTransform: "none",
  },
  homeButton: {
    width: "100%",
    maxWidth: "300px",
    height: "50px",
    textTransform: "none",
  },
};

const ThankYou = () => {
  return (
    <div>
      <Helmet>
        <title>Thank You | The Shepstone Experience</title>
        <meta
          name={"description"}
          content={"We look forward to seeing you at The Shepstone Experience!"}
        ></meta>
      </Helmet>

      <Header
        bgImg={"/images/SG-Night-Open-Day-Header.jpg"}
        headText={"THANK YOU!"}
      />

      <LineSeparator noLine />
      <LineSeparator noLine />

      <GreenBlockSection
        headText={"your payment successfully"}
        subHeadText={"We've captured"}
      >
        <p className={"venue-intro-text"}>
          If you have any questions about your tickets or the event, you are welcome to call us or chat with us via WhatsApp or email. Otherwise we will see you there!
        </p>
      </GreenBlockSection>

      <LineSeparator noLine />
      <Link to={"/"}>
        <Button
          variant={"contained"}
          color={"primary"}
          style={styles.homeButton}
        >
          Return to home
        </Button>
      </Link>
      <LineSeparator noLine />

      <LineSeparator />
      <p className={"text-404"}>If you'd like to chat with us, feel free to send us a WhatsApp and we'll help you however we can!</p>
      <Button
        href={"https://wa.me/27678170890"}
        target={"_blank"}
        startIcon={<WhatsApp />}
        variant={"contained"}
        color={"secondary"}
        style={styles.contactButton}
      >
        Start chat
      </Button>
      
      <LineSeparator noLine />
    </div>
  );
};

export default ThankYou;

