import "./market.css";

import React from "react";
import { TextField, CircularProgress, Button, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Helmet } from "react-helmet";

import { LOADING_STATE } from "../../utils/stateTypes";
import Header from "../sections/Header";
import LineSeparator from "../sections/LineSeparator";
import GreenBlockSection from "../sections/GreenBlockSection";

const styles = {
  input: {
    marginBottom: 15,
    textTransform: "none",
    textAlign: "left",
  },
  submitButton: {
    width: 220,
    backgroundColor: "#fff",
    color: "#6F766B",
    marginTop: 10,
    textTransform: "none",
  },
};

const sendMessage = async (name, company, size, selling, cell, email, plug, setSendState) => {
  setSendState(LOADING_STATE.LOADING);
  const response = await fetch("/api/Contact/SendMarketVendorApplication", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      company,
      size,
      selling,
      cell,
      email,
      plug,
    }),
  });
  if (response.ok) {
    setSendState(LOADING_STATE.LOADED);
  }
  else {
    setSendState(LOADING_STATE.ERROR);
  }
};


const MarketVendor = () => {
  const [name, setName] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [size, setSize] = React.useState("");
  const [selling, setSelling] = React.useState("");
  const [cell, setCell] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [plug, setPlug] = React.useState("");
  const [sendState, setSendState] = React.useState(LOADING_STATE.NOT_LOADING);

  let submitLabel = "Submit application";
  if (sendState === LOADING_STATE.LOADING) {
    submitLabel = <CircularProgress size={25} />;
  }
  else if (sendState === LOADING_STATE.LOADED) {
    submitLabel = "Sent!";
  }
  else if (sendState === LOADING_STATE.ERROR) {
    submitLabel = "Retry";
  }

  return (
    <div>
      <Helmet>
        <title>Christmas Market | The Shepstone Experience</title>
        <meta
          name={"description"}
          content={"Become a vendor at the Shepstone Christmas Market."}
        ></meta>
      </Helmet>
    
      <Header
        bgImg={"/images/market/vendor-header1.jpg"}
        headText={"BECOME A VENDOR AT OUR CHRISTMAS MARKET"}
      />

      <LineSeparator noLine />

      <section className={"market-intro-section"}>
        <GreenBlockSection
          headText={"Christmas Market"}
          subHeadText={"Shepstone"}
        >
          <div className={"market-details-block"}>
            <p className={"market-sub-head"}>Dates & Times</p>
            <p className={"market-details"}>Thursday, 14 November 2024 from 10:00 - 20:00</p>
            <p className={"market-details"}>Friday, 15 November 2024 from 10:00 - 22:00</p>
          </div>

          <div className={"market-details-block"}>
            <p className={"market-sub-head"}>Book your stand now!</p>
            <p className={"market-details"}>3x2m stand: R3500</p>
            <p className={"market-details"}>2x2m Stand: R2500</p>
          </div>

          <div className={"market-details-block"}>
            <p className={"market-sub-head"}>This fee includes:</p>
            <p className={"market-details"}>Rental fee for two days</p>
            <p className={"market-details"}>Table, chairs and table cloth for each stand</p>
            <p className={"market-details"}>Plug point if needed (limited availability)</p>
            <p className={"market-details"}>Exposure across our socials</p>
          </div>

          <Button
            variant={"contained"}
            color={"primary"}
            href={"/images/market/Christmas-market_terms-and-conditions.pdf"}
            target={"_blank"}
          >
            Read our terms and conditions
          </Button>
        </GreenBlockSection>
      </section>

      <form
        className={"message-form standard-width"}
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage(name, company, size, selling, cell, email, plug, setSendState);
        }}
      >
        <h3>Book your spot!</h3>
        <div className={"message-form-inner"}>
          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Name"}
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          
          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Company Name"}
            fullWidth
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            required
          />

          <FormControl
            style={styles.input}
            variant={"outlined"}
            fullWidth
            required
          >
            <InputLabel>Size</InputLabel>
            <Select
              value={size}
              onChange={(e) => setSize(e.target.value)}
              label={"Size"}
              required
            >
              <MenuItem value={"3x2m"}>3x2m</MenuItem>
              <MenuItem value={"2x2m"}>2x2m</MenuItem>
            </Select>
          </FormControl>
          
          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"What will you be selling?"}
            fullWidth
            value={selling}
            onChange={(e) => setSelling(e.target.value)}
            required
          />

          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Cell Number"}
            fullWidth
            value={cell}
            onChange={(e) => setCell(e.target.value)}
            type={"tel"}
            required
          />

          <TextField
            style={styles.input}
            variant={"outlined"}
            label={"Email Address"}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type={"email"}
            required
          />

          <FormControl
            style={styles.input}
            variant={"outlined"}
            fullWidth
            required
          >
            <InputLabel>Do you need a plug point?</InputLabel>
            <Select
              value={plug}
              onChange={(e) => setPlug(e.target.value)}
              label={"Do you need a plug point?"}
              required
            >
              <MenuItem value={"yes"}>Yes</MenuItem>
              <MenuItem value={"no"}>No</MenuItem>
            </Select>
          </FormControl>
          {
            (sendState === LOADING_STATE.LOADED) ? (
              <p>Thank you! One of our staff members will be in contact with you to confirm your booking.</p>
            ) : (
              <p>
                By submitting this form, it means you have read and understood our
                {" "}
                <a
                  href={"/images/market/Christmas-market_terms-and-conditions.pdf"}
                  target={"_blank"}
                  rel={"noreferrer"}
                  style={{ color: "#fff", textDecoration: "underline" }}
                >
                  Terms and Conditions
                </a>
              </p>
            )
          }

          <Button
            variant={"contained"}
            style={styles.submitButton}
            color={"primary"}
            type={"submit"}
            disabled={(sendState === LOADING_STATE.LOADING) || (sendState === LOADING_STATE.LOADED)}
          >
            {submitLabel}
          </Button>
        </div>
      </form>
      
    </div>
  );
};

export default MarketVendor;

